define(['app'], function(app) {
  
  var _dorCheck = {

    _data: {
      missingOrderId: 63,
      notReceivedOptionId: 3,
      trackedShowsDeliveredButNotReceivedOptionId: 4,
    },

    _cssClasses: {
      showDorOptions: 'myAccountSection_dorCheckOptionsBlock-show',
      showDorDownload: 'myAccountSection_dorDownload-show'
    },

    _elements: {
      dorCheckCategories: '.js-dor-check-categories'
    },

    _dorCheckBlock: document.querySelector('[data-component="myAccountDorCheck"]'),
    // This has to stay as a class as I can't add a data attribute into the Spring form tag
    _categoryOptions: document.querySelector('.js-dor-check-categories'),
    _dorCheckOptions: document.querySelector('[data-dor-check="options"]'),
    _dorDownload: document.querySelector('[data-dor-check="download"]'),

    _init: function() {
      _dorCheck._bindEvents();
    },

    _bindEvents: function() {
      app.element.on('change', _dorCheck._checkSelectedCategory,
        _dorCheck._categoryOptions);

      app.element.on('change', _dorCheck._checkSelectedDorReason,
        _dorCheck._dorCheckOptions);
    },

    _checkSelectedCategory: function() {
      var categoryOptions = document.querySelector(_dorCheck._elements.dorCheckCategories);

      if (categoryOptions) {
        var categoryId = categoryOptions.value;
        if (parseInt(categoryId, 10) === _dorCheck._data.missingOrderId) {
          _dorCheck._showDorOptions();
        } else {
          _dorCheck._hideDorOptions();
        }
      }
    },

    _showDorOptions: function() {
      if (_dorCheck._dorCheckBlock) {
        app.element.addClass(_dorCheck._cssClasses.showDorOptions, _dorCheck._dorCheckBlock);
      }
    },

    _hideDorOptions: function() {
      if (_dorCheck._dorCheckBlock) {
        app.element.removeClass(_dorCheck._cssClasses.showDorOptions, _dorCheck._dorCheckBlock);
      }
    },

    _checkSelectedDorReason: function() {
      var dorCheckOptions = document.querySelector('[data-dor-check="options"]');
      var reasonId = null;

      if (dorCheckOptions) {
        reasonId = dorCheckOptions.value;
        if (parseInt(reasonId, 10) === _dorCheck._data.notReceivedOptionId ||
          parseInt(reasonId, 10) === _dorCheck._data.trackedShowsDeliveredButNotReceivedOptionId) {
          _dorCheck._showDorDownload();
        } else {
          _dorCheck._hideDorDownload();
        }
      }
    },

    _showDorDownload: function() {
      if (_dorCheck._dorDownload) {
        app.element.addClass(_dorCheck._cssClasses.showDorDownload, _dorCheck._dorDownload);
      }
    },

    _hideDorDownload: function() {
      if (_dorCheck._dorDownload) {
        app.element.removeClass(_dorCheck._cssClasses.showDorDownload, _dorCheck._dorDownload);
      }
    },
  };

  // Execute module constructor
  if (document.querySelector('[data-component="myAccountDorCheck"]')) _dorCheck._init();

  // Unit testing access
  return _dorCheck;
});
